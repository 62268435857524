<template>
  <div class="containers">
    <div class="selfStyle">
      <div class="title">职业道德承诺书</div>
      <div class="inputBox">
        <div class="textIndent">
          本人自愿承诺在岗期间严格遵守以下工作原则：
        </div>
        <div class="textIndent">
          一、恪守诚信，保证所提供的个人简历、证件复印件真实、有效、无误，绝无欺诈成份。
        </div>
        <div class="textIndent">
          二、认真遵守和履行公司的各项规章制度，如因本人原因（过失或故意违规操作）给公司造成经济损失或法律上的责任，本人愿承担相关责任，赔偿公司的经济损失。
        </div>
        <div class="textIndent">
          三、严格遵守在工作期间严禁从事第二职业的相关规定。如有违反愿意接受被内部通报或通过媒体通报及被开除的处理决定，并且将退回在岗期间薪酬的50%及当年的全部奖金。 严格遵守工作期间如登录非经批准的各类软件、QQ以及通过远程或其它方式登录非公司电脑或非经授权的公司电脑视为有兼职行为发生的规定。
        </div>
        <div class="textIndent">
          四、不损害公司利益，不利用职务之便徇私舞弊，不虚报报销费用。
        </div>
        <div class="textIndent">
          五、在职期间，若发生与本人业务相关的经济问题，本人愿接受公司的调查并积极配合。
        </div>
        <div class="textIndent">
          六、若因故离职，须按公司要求提前30天申请，并遵守公司关于离职交接的相关规定。
        </div>
        <div class="textIndent">
          七、若被公司证明或认为不符合任职条件，公司有权提前一个月（重大过失除外）以书面形式通知本人办理离职手续，并同意履行公司关于离职交接的相关规定。
        </div>
        <div class="textIndent">
          八、如违反公司的管理规章制度及上述承诺，愿意接受公司辞退、罚款等处罚。
        </div>
        <div class="textIndent">
          九、如侵占财物、货款或其他侵权行为，致使公司蒙受财物损失时，本人愿负完全赔偿责任。
        </div>
        <div class="textIndent">
          十、不泄露公司的商业信息，遵守公司关于保密信息的相关规定，并承诺在离职后两年内不从事本行业（包括但不限于自营或者为他人经营生产与公司同类的产品，从事同类的业务，不得在与公司有竞争关系的单位担任任何职务，包括股东、合伙人、董事、监事、经理、职员、代理人、顾问等）;如有违反，视情节严重程度，根据公司要求赔偿公司5-10万元。
        </div>

        <div class="sign">
          <span class="signLabel" style="font-weight: bold; color: #666666">承诺人:</span>
          <img v-if="query.sign_file"  class="card" :src="query.sign_file" alt="">
          <vue-esign v-else  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :lineColor="'#000'" />
        </div>
        <div style="display: flex;justify-content: flex-end">
          <div class="reset" @click="resetSign">重新签名</div>
        </div>
        <div class="titlesFlex">
          <span style="font-weight: bold; color: #666666">日期：</span>
          <van-field class="inputs inputBorder36" v-model="query.select_year" />
          年
          <van-field class="inputs inputBorder36" v-model="query.select_month" />
          月
          <van-field class="inputs inputBorder36" v-model="query.select_day" />
          日
        </div>
      </div>


      <div class="submitBtn" @click="submitInfo">保存</div>
    </div>
  </div>
</template>

<script>
import {LoadPromise, SavePromise, uploadFile} from "@/api/paper";
import axios from "axios";

export default {
  data() {
    return {
      query: {},
      company_name: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    },
    loadData() {
      LoadPromise({openId: this.$store.state.info.openId}).then(res => {
        if(res.row && res.row.sign_file) {
          this.query = res.row
        }
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      SavePromise(this.query).then(res => {
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$router.go(-1)
        }
      })
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submitInfo() {
      if(!this.query.select_year || !this.query.select_month || !this.query.select_day) {
        return this.$notify({
          type: "warning",
          message: "请将日期填写完整",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.containers {
  box-sizing: border-box;
  padding: 14px 10px;
}
.inputs {
  display: flex;
  align-items: center;
  .inputsItem {
    font-size: 12px;
    border-bottom: 1px solid #DDDDDD;
    text-align: center !important;
    padding: 0 !important;
  }
}
.inputBox {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  color: #666666;
  line-height: 20px;
  .itemTitle {
    font-weight: bold;
  }
}
.tips {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 11px;
  color: #777777;
  line-height: 18px;
  margin: 18px 0;
}
.sign {
  margin-top: 50px;
  .signLabel {
    flex-shrink: 0;
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
  }
  .card {
    width: 100%;
    height: 70px;
    background: #F7F8FA;
    border: 1px solid #DDDDDD;
  }
}
  .reset {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #02DBCA;
    color: #02DBCA;
    border-radius: 10px;
    margin-top: 15px;
  }
.submitBtn {
  background: #02DBCA;
  border-radius: 20px;
  color: #FFFFFF;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.titlesFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.inputBorder36 {
  width: 36px;
}
.inputs {
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;
  text-align: center !important;
  padding: 0 !important;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 60px 0;
  color: #000000;
}
.input50 {
  width: 45px !important;
}
.input130 {
  width: 130px;
}
.input80 {
  width: 80px;
}
.textIndent {
  text-indent: 2em;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.contract-term {
  display: flex;
  align-items: center; /* 垂直居中 */
  flex-wrap: wrap; /* 允许内容自动换行 */
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
