import request from '../util/request'

//获取用户信息
export function GetPaperItem(data) {
    return request({
        url: '/api/XmhHr/LoadInfo',
        method: 'post',
        data
    })
}
export function saveEntry(data) {
    return request({
        url: '/api/XmhHr/SaveEntry',
        method: 'post',
        data
    })
}
export function loadEntry(data) {
    return request({
        url: '/api/XmhHr/LoadEntry',
        method: 'post',
        data
    })
}
export function SavePromise(data) {
    return request({
        url: '/api/XmhHr/SavePromise',
        method: 'post',
        data
    })
}
export function LoadProtocol(data) {
    return request({
        url: '/api/XmhHr/LoadProtocol',
        method: 'post',
        data
    })
}

export function LoadPromise(data) {
    return request({
        url: '/api/XmhHr/LoadPromise',
        method: 'post',
        data
    })
}
export function SaveContract(data) {
    return request({
        url: '/api/XmhHr/SaveContract',
        method: 'post',
        data
    })
}
export function LoadContract(data) {
    return request({
        url: '/api/XmhHr/LoadContract',
        method: 'post',
        data
    })
}
export function SaveInsurance(data) {
    return request({
        url: '/api/XmhHr/SaveInsurance',
        method: 'post',
        data
    })
}
export function LoadInsurance(data) {
    return request({
        url: '/api/XmhHr/LoadInsurance',
        method: 'post',
        data
    })
}
export function SaveRenewalContract(data) {
    return request({
        url: '/api/XmhHr/SaveRenewalContract',
        method: 'post',
        data
    })
}
export function SaveCertificate(data) {
    return request({
        url: '/api/XmhHr/SaveCertificate',
        method: 'post',
        data
    })
}
export function LoadCertificate(data) {
    return request({
        url: '/api/XmhHr/LoadCertificate',
        method: 'post',
        data
    })
}
export function SubmitInfo(data) {
    return request({
        url: '/api/XmhHr/SubmitInfo',
        method: 'post',
        data
    })
}
export function uploadFile(data) {
    return request({
        url: '/api/XmhHr/UploadImg',
        method: 'post',
        data
    })
}
export function SaveEducationalLoss(data) {
    return request({
        url: '/api/XmhHr/SaveEducationalLoss',
        method: 'post',
        data
    })
}
export function LoadEducationalLoss(data) {
    return request({
        url: '/api/XmhHr/LoadEducationalLoss',
        method: 'post',
        data
    })
}
export function SaveProtocol(data) {
    return request({
        url: '/api/XmhHr/SaveProtocol',
        method: 'post',
        data
    })
}
export function LoadRegularContract(data) {
    return request({
        url: '/api/XmhHr/LoadRegularContract',
        method: 'post',
        data
    })
}
export function SaveRegularContract(data) {
    return request({
        url: '/api/XmhHr/SaveRegularContract',
        method: 'post',
        data
    })
}
export function LoadRenewalContract(data) {
    return request({
        url: '/api/XmhHr/LoadRenewalContract',
        method: 'post',
        data
    })
}
export function SubmitContrat(data) {
    return request({
        url: '/api/XmhHr/SubmitContrat',
        method: 'post',
        data
    })
}
